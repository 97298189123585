import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'

const ForbiddenPage = () => {
  return (
    <Layout>
      <Row className="d-flex justify-content-center">
        <Col xs={8}>
          <Link to="/" className="button">
            Home 
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </Col>
      </Row>
    </Layout>
  )
}

export default ForbiddenPage

export const Head = () => <title>Unauthorized</title>
